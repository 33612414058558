@use "../../../styles/constants/devices.scss" as *;

.stats {
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease-in 0s;
  border: 1px solid var(--statsTipster-borderColor);
  border-radius: 8px;
  padding: 0;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0 0;
  background-color: var(--contentHeaderBackground);
  color: var(--contentHeaderFontColor);
  border-bottom: 1px solid var(--statsTipster-borderColor);
  padding: 8px 16px;
}

.header {
  text-transform: capitalize;
  font-weight: 500;
}

.percentageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 16px;

  & > div {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;

    & > span {
      line-height: 1em;

      &:first-of-type {
        font-size: 1rem;
        margin-bottom: 8px;
        font-weight: bold;
        color: var(--defaultText);
      }

      &:nth-of-type(n + 2) {
        font-size: 0.8125rem;
        margin-bottom: 8px;
        font-weight: 400;
        text-transform: capitalize;
        color: var(--statsTipster-subText);
      }

      &:nth-of-type(n + 3) {
        font-size: 0.625rem;
        font-weight: 400;
        color: var(--statsTipster-subText);
      }
    }
  }
}

.formTabs {
  display: flex;
  flex-direction: row;
  background-color: var(--statsTipster-headerBackgroundColor);
  position: relative;

  & > hr {
    position: absolute;
    bottom: 0;
    margin: 0;
    height: 2px;
    width: 50%;
    background-color: var(--statsTipster-teamSelector);
    border: none;
    transition: left 0.3s linear 0s;
  }

  &--home > hr {
    left: 0;
  }

  &--away > hr {
    left: 50%;
  }
}

.formTab {
  flex: 1 1 0%;
  font-weight: 500;
  color: var(--statsTipster-inactiveTabFontColor);
  padding: 12px 16px;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
  border: none;
  background: none;

  &[aria-pressed="true"] {
    color: var(--statsTipster-headerFontColor);
  }
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 0.8125rem;
  color: var(--statsTipster-tableFontColor);

  & > tbody {
    & > tr {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid var(--statsTipster-borderColor);
      padding: 0 8px;

      & > td {
        font-weight: 400;
        padding: 8px 0;
        flex: 3 1 !important;
      }
    }
  }
}

.win {
  font-weight: 700 !important;
}
