.customTipContainer {
  padding: 1rem;
}

.tipWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContainer {
  width: 80%;
}

.header {
  margin: 0;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  border: none;
  background-color: transparent;
  margin: 1rem;
}

.icon {
  color: var(--defaultText);
  height: 20px;
}

.editor {
  border-left: 4px solid var(--cardWrapper-highlight);
  padding: 0.25rem !important;
  padding-left: 0.5rem !important;
  margin-top: 0.25rem;
}
