@use "../../../styles/constants/devices.scss" as *;

.container {
  margin-bottom: 16px;
  background-color: var(--tipsterBetBuilder-backgroundColor);

  @media only screen and (min-width: $tablet) {
    border-radius: 8px;
  }
}

.containerHeader {
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid var(--tipsterBetBuilder-borderColor);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-montserrat);
  font-size: 0.875rem;
  font-weight: 800;
  text-transform: uppercase;

  & div:nth-child(1) {
    width: 80%;
  }
}

.oddsBox {
  display: flex;
  justify-content: center;
  font-size: 0.625rem;
  padding: 5px 8px;
  border: 1px solid var(--tipsterBetBuilder-secondaryBorderColor);
  border-radius: 4px;
}

.startDate {
  font-size: 0.688rem;
  margin: 0;
}

.matchInfo {
  padding: 16px;
  border-bottom: 1px solid var(--tipsterBetBuilder-borderColor);
}

.tipReason {
  font-size: 0.813rem;
  padding: 16px;

  & p {
    margin: 0;
  }
}

.selection {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: var(--font-montserrat);
  font-size: 0.875rem;
  font-weight: 700;
  padding: 20px 16px 20px 0;
  border-left: 2px solid var(--tipsterBetBuilder-secondaryBorderColor);
  border-bottom: 1px solid var(--tipsterBetBuilder-borderColor);
  min-height: 68px;

  &::before {
    content: "";
    position: absolute;
    top: 30px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid var(--tipsterBetBuilder-secondaryBorderColor);
  }

  & span {
    margin: 0 0 0 16px;
  }

  & button {
    background: var(--tipsterBetBuilder-borderColor);
    color: var(--tipsterBetBuilder-buttonFontColor);
    font-family: var(--font-montserrat);
    font-size: 0.6rem;
    font-weight: 800;
    text-transform: uppercase;
    padding: 8px;
    border: none;
    border-radius: 4px;
  }

  & [aria-pressed="true"] {
    background: var(--tipsterBetBuilder-activeButtonColor);
    color: var(--tipsterBetBuilder-activeButtonFontColor);
  }
}

.reason {
  flex: 1 1 100%;
  font-family: var(--font-inter);
  font-size: 0.813rem;
  font-weight: 400;
  padding: 16px 16px 0;
  margin: 0 !important;
}

.bookmakers {
  padding: 8px 0;
  border-top: 1px solid var(--tipsterBetBuilder-borderColor);
}

.bookmakersHeader {
  display: grid;
  grid-template-columns: 1.6fr 0.8fr 0.8fr;
  column-gap: 8px;
  justify-items: center;
  margin-bottom: 16px;
  font-size: 0.688rem;
  border-bottom: 1px solid var(--tipsterBetBuilder-borderColor);
  padding: 0 16px 8px;

  & span {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.bookmakersRow {
  display: grid;
  grid-template-columns: 40px 1.35fr 0.8fr 0.8fr;
  column-gap: 8px;
  margin-bottom: 8px;
  justify-items: center;
  align-items: center;
  font-family: var(--font-montserrat);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0 16px;

  & div {
    width: 100%;
  }
}

.returns {
  display: flex;
  justify-content: center;
  border: 1px solid var(--tipsterBetBuilder-borderColor);
  border-radius: 6px;
  padding: 12px 0;
}

.bookmakerLink {
  border-radius: 6px;
  border: 0;
  background: var(--tipsterBetBuilder-borderColor);
  color: var(--tipsterBetBuilder-buttonFontColor);
  font-family: var(--font-montserrat);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 12px 0;
  width: 100%;
}

.moreOddsContainer {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  border-top: 1px solid var(--tipsterBetBuilder-borderColor);

  button {
    font-family: var(--font-montserrat);
    font-size: 0.75rem;
    font-weight: 700;
    background: none;
    color: var(--tipsterBetBuilder-buttonFontColor);
    border: 0;
  }
}
