.competitionInfo {
  display: block;
  font-size: 0.688rem;
  margin-bottom: 8px;
}

.teamInfo {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  font-size: 0.813rem;
  font-weight: 600;

  & div {
    display: flex;
    align-items: center;
  }
}
