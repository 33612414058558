.competitionInfo {
  display: block;
  font-size: 0.688rem;
  margin-bottom: 8px;
}

.teamInfo {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  font-size: 0.813rem;
  font-weight: 600;

  & div {
    display: flex;
    align-items: center;
  }
}

.divider {
  margin: 5px 0;
  font-size: 0.563rem;

  & span {
    padding: 0 10px 0 5px;
  }

  & hr {
    width: 100%;
    border: 1px solid var(--tipsterBetBuilder-borderColor);
    border-left: 0;
    margin: 0;
  }
}
