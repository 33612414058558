@use "../../styles/constants/devices.scss" as *;

.gridWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 5px;
  -webkit-box-align: center;
  align-items: center;
  column-gap: 5px;
  margin: 0;
  padding: 0.5rem 1rem;

  @media screen and (min-width: $tablet) {
    display: grid;
    grid-template-columns: 90px 1fr 154px;
    padding: 0 1rem 1rem;
  }

  & > div {
    font-size: 0.8125rem;
    font-weight: bold;
    line-height: 1.25rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      grid-column: span 3 / auto;
    }

    @media screen and (min-width: $tablet) {
      &:last-child {
        grid-column: auto;
      }
    }
  }

  &--includesSignUpLink {
    grid-template-columns: 1fr 2fr 1fr;

    @media screen and (min-width: $tablet) {
      display: grid;
      grid-template-columns: 90px 1fr 120px 154px;
    }
  }
}

.imageWrapper {
  position: relative;
  height: 50px;
  width: 50px;
}

.returns {
  justify-content: flex-start !important;
}

.signUpLinkWrapper > a {
  font-size: 0.725rem;
}
